import randomize from './randomizer';

const introPhrases = [
    "your recipe",
    "a taco to ruin all other tacos",
    "better get your taco-makin' pants out",
    "better get your taco-makin' pants out",
];

export function getIntroPhrase() {
    return randomize(introPhrases);
}
