import React from 'react';
import { Container } from './styles';

export function AdSpace({ children, adType }) {
    return (
        <Container $adType={adType}>
            <small>{adType}</small>
            { children }
        </Container>
    );
}

export default AdSpace;
