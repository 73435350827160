import randomize from './randomizer';

const difficultyMap = {
    1: ['cheater', "child's play"],
    2: ['easy', 'simple'],
    3: ['intermediate', 'involved'],
    4: ['advanced', 'hard'],
    5: ['heroic', 'chef-mode', 'expert'],
};

export const difficulty = (difficultyCode) => {
    return randomize(difficultyMap[difficultyCode])
};
