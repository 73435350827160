import difficulties from '../constants/difficulties';

const difficultiesMap = {
    [difficulties.ANY]: [1,2,3,4,5],
    [difficulties.EASY]: [1,2,3],
    [difficulties.MODERATE]: [2,3,4],
    [difficulties.ADVANCED]: [3,4,5],
};

export const difficultyFilter = (recipes, difficulty) => {
    try {
        const filteredRecipes = recipes.filter(recipe =>
            difficultiesMap[difficulty || difficulties.ANY].includes(recipe.difficulty));
        if (difficulty === difficulties.ANY || filteredRecipes.length < 1 || !difficulty) {
            return recipes
        }
        return filteredRecipes;
    } catch (e) {
        return recipes;
    }
};

export default difficultyFilter;
