import {  } from '../standard/additionalToppings';
import { SLICED_RADISH, CILANTRO } from '../standard/garnish';
import {  } from '../standard/mixins';
import {COTIJA, QUESO_FRESCO} from '../standard/queso';
import {
    TRADITIONAL_TACO_RELISH,
    GUAC,
    CORN_RELISH
} from '../standard/toppings';
import {  } from '../standard/tortilla';

const MEXICAN = {
    toppings: [
        TRADITIONAL_TACO_RELISH,
        TRADITIONAL_TACO_RELISH,
        GUAC,
        CORN_RELISH
    ],
    additionalToppings: [],
    garnishes: [SLICED_RADISH, CILANTRO],
    mixins: [],
    queso: [COTIJA, QUESO_FRESCO],
    tortilla: []
};

export default MEXICAN;
