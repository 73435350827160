import randomize from './randomizer';

import {isEmpty} from 'lodash';

const prePreviewHyperbole = [
    "Awesome",
    "Ballin'",
    "Bangin'",
    "Bangkok Dangerous",
    "Bodacious",
    "Boss-mode",
    "Cataclysmic",
    "Chingón",
    "Dank",
    "Delicious",
    "Devastating",
    "Dope",
    "Earth-shattering",
    "Fabulicious",
    "Fergalicious",
    "Filthy",
    "Full-Send",
    "Fantastic",
    "Heavenly",
    "Heady",
    "Insane",
    "Life-changing",
    "Loco-delicioso",
    "Lusty",
    "Luscious",
    "Mad-yummy",
    "Nummy",
    "Nibbly bits of",
    "Paradigm-shifting",
    "Ratchet",
    "Sacrilicious",
    "Sensual",
    "Straight-up lit",
    "Sweet, luscious",
    "Sultry",
    "Sleazy",
    "Trashy",
    "Tantalizing",
    "Yummy-scrumbos"
];

export function getPreTitleHyperbole(base) {
    const firstLetter = base.name.charAt(0).toUpperCase();
    const filtered = prePreviewHyperbole.filter(word => word.charAt(0).toUpperCase() === firstLetter);
    const getStringArray = isEmpty(filtered) ? prePreviewHyperbole : filtered;
    return randomize(getStringArray);
}

export default getPreTitleHyperbole;
