import React from 'react';
import { LayoutBlock } from '..';

const stylesToInject = { textAlign: 'center', color: 'silver'};
const InspirationalQuote = ({welcomeMessage}) => (
    <React.Fragment>
        <LayoutBlock flexCenter marginTop={4} injectStyles={stylesToInject}>
            <h3><i>{welcomeMessage.quote}</i></h3>
        </LayoutBlock>
        <LayoutBlock flexCenter marginTop={2} injectStyles={stylesToInject}>
            <h4>-{welcomeMessage.author}</h4>
        </LayoutBlock>
    </React.Fragment>
);

export default InspirationalQuote;
