import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { animateScroll as scroll } from 'react-scroll';
import I from '../../assets/svgs/I';
import { FooterContainer } from "./styles";
import { ExtraInfoContainer, InfoContainer } from "../Card/styles";

function toggleExtraInfo(){
    scroll.scrollToBottom();
}

export const Footer = ({children, extraInfo}) => (
    <Accordion>
        <FooterContainer $extraInfo={extraInfo}>
            {children}
            <InfoContainer $extraInfo={extraInfo} onClick={() => scroll.scrollToBottom}>
                <Accordion.Toggle onClick={() => toggleExtraInfo()} as={I} variant="link" eventKey='0'/>
            </InfoContainer>
            <Accordion.Collapse eventKey="0">
                <ExtraInfoContainer>
                    {extraInfo}
                </ExtraInfoContainer>
            </Accordion.Collapse>
        </FooterContainer>
    </Accordion>
);

export default Footer;
