import { styled } from 'styletron-react';
import { colors } from '../../constants/commonStyles.js';

export const ButtonWrapper = styled('div', () => ({
   cursor: 'pointer',
}));

export const HeaderContainer = styled('div', () => ({
    maxWidth: '100%',
    overflowX: 'hidden',
    overflow: 'hidden',
    zIndex: '100000000000000000',
    width: '100%',
    borderRight: "none",
    display: 'flex',
    boxShadow: '0 2px 5px 0 rgba(99,115,129,0.35)',
    backgroundColor: colors().white,
}));

export default {
    HeaderContainer,
    ButtonWrapper,
};
