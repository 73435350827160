import { styled } from 'styletron-react';
import { media, unit } from '../../constants/commonStyles';

export const Container = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: "100%",
    padding: unit[2],
    minHeight: `${window.innerHeight}px`,
    overflow: 'scroll',
    [`@media (min-width: ${media.desktopMinWidth})`]: {
        padding: unit[3]
    },
}));

export default {
    Container,
};
