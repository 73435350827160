import React from 'react';
import { Container } from './styles';

const LayoutBlock = ({
	children,
	flexCenter,
	horizontalSpacer,
	flexCenterResponsive,
	rowIfDesktop,
	row,
	rowReverseIfDesktop,
	flexEnd,
	flexGrow,
	injectStyles,
	marginBottom,
	marginTop,
	halfWidthIfDesktop,
	...props
}) => {
	return (
		<Container
			$flexCenterResponsive={flexCenterResponsive}
			$rowIfDesktop={rowIfDesktop}
			$row={row}
			$rowReverseIfDesktop={rowReverseIfDesktop}
			$horizontalSpacer={horizontalSpacer}
			$injectStyles={injectStyles}
			$marginTop={marginTop}
			$marginBottom={marginBottom}
			$flexCenter={flexCenter}
			$flexEnd={flexEnd}
			$flexGrow={flexGrow}
			$halfWidthIfDesktop={halfWidthIfDesktop}
			{...props}
		>
			{children}
		</Container>
	);
};

LayoutBlock.defaultProps = {
	marginBottom: 0,
	marginTop: 2,
};

export default LayoutBlock;
