import { styled } from 'styletron-react';
import {media} from '../../constants/commonStyles';

export const ImgContainer = styled('img', () => ({
    height: '100%',
    width: '100%',
}));

export const ImgOverlay = styled('div', ({$overlayOpacity}) => ({
    height: '100%',
    width: '100%',
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: `rgba(245,246,250,${$overlayOpacity})`,
}));

export const LogoContainer = styled('div', ({$size, $mobileSize}) => ({
    width: `${$size}px`,
    height: `${$size}px`,
    [`@media (max-width: ${media.mobileMaxWidth})`]: {
        height: `${($mobileSize || $size)}px`,
        width: `${($mobileSize || $size)}px`,
    },
    position: 'relative',
    zIndex: '50',
}));

export default {ImgContainer, ImgOverlay, LogoContainer};
