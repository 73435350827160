import { baseTypes } from '../../constants'

export const SOUR_CREAM = {
    name: "sour cream",
    code: "sc",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "sour cream",
            measurement: "3 oz",
            application: "a dollop on top",
            imageCode: "sour-cream"
        }
    ]
};

export const QUESO_FRESCO = {
    name: "queso fresco",
    code: "qf",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "queso fresco",
            measurement: "3 oz crumbled",
            application: "top to taste",
            imageCode: "cotija"
        }
    ]
};

export const COTIJA = {
    name: "cotija cheese",
    code: "cjc",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "cotija cheese",
            measurement: "3 oz crumbled",
            application: "top to taste",
            imageCode: "cotija"
        }
    ]
};

export const CHEDDAR = {
    name: "shredded cheddar",
    code: "shcd",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "cheddar",
            measurement: "4 oz shredded",
            application: "top to taste",
            imageCode: "sour-cream"
        }
    ]
};

export const WHITE_CHEDDAR = {
    name: "shredded white cheddar",
    code: "wcd",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "white cheddar",
            measurement: "4 oz shredded",
            application: "top to taste",
            imageCode: "white-cheddar"
        }
    ]
};

export const MANCHEGO = {
    name: "grated manchego",
    code: "gm",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "manchego cheese",
            measurement: "2 oz shredded",
            application: "top to taste",
            imageCode: "manchego"
        }
    ]
};

export const SMOKED_GOUDA = {
    name: "shredded smoked gouda",
    code: "smg",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "smoked gouda",
            measurement: "4 oz shredded",
            application: "top to taste",
            imageCode: "gouda"
        }
    ]
};

export const MONTEREY_JACK = {
    name: "monterey jack",
    code: "mj",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "monterey jack",
            measurement: "4 oz shredded",
            application: "top to taste",
            imageCode: "jack"
        }
    ]
};

export const FETA = {
    name: "crumbled feta",
    code: "cft",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "feta",
            measurement: "2 oz crumbled",
            application: "top to taste",
            imageCode: "feta"
        }
    ]
};

export const MARINATED_FETA = {
    name: "marinated feta",
    code: "mnft",
    basePairings: [
        baseTypes.LAMB,
        baseTypes.BEEF,
    ],
    ingredients: [
        {
            name: "feta",
            measurement: "2 oz crumbled",
            application: "top to taste",
            imageCode: "feta"
        }
    ]
};

export const QUESO = [
    CHEDDAR,
    COTIJA,
    FETA,
    MARINATED_FETA,
    MONTEREY_JACK,
    MANCHEGO,
    SMOKED_GOUDA,
    SOUR_CREAM,
    QUESO_FRESCO,
    WHITE_CHEDDAR
];

export default QUESO;
