import React from 'react';
import { Card, LayoutBlock } from '..';

const IngredientList = ({ list }) => (
    <Card>
        <LayoutBlock flexEnd marginTop={0} injectStyles={{minWidth: '500px'}} />
        <ul>
            {list.map((ingredient, i) => (
                <li key={i}>{ingredient.measurement} {ingredient.name}</li>
            ))}
        </ul>
    </Card>
);

export default IngredientList;
