import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { LayoutBlock, Checkbox, SelectDifficulty, Button, AnimationWrapper } from '..';
import {isEmpty} from "lodash";

const Options = (props) => {
    const {
        optionButtonsValue,
        wantsCheese,
        VEGAN,
        GLUTEN,
        isProcessing,
        onRecipeGenerate,
        recipe,
        onVeganSelection,
        onGlutenSelection,
        onCheeseSelection,
    } = props;

    const buttonLabel = isEmpty(recipe) ? 'generate recipe' : 'generate new recipe';

    if (optionButtonsValue) {
        return (
            <LayoutBlock marginTop={1}>
                <Accordion>
                    <LayoutBlock flexCenter row marginTop={1}>
                        <AnimationWrapper cancelWidth fromLeft>
                            <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                                options
                            </Accordion.Toggle>
                        </AnimationWrapper>
                        <LayoutBlock horizontalSpacer={3} marginTop={0} />
                        <AnimationWrapper cancelWidth fromRight>
                            <Button
                                type='secondary'
                                isLarge
                                isProcessing={isProcessing || !optionButtonsValue}
                                isCardWidth
                                isSecondary
                                onClick={onRecipeGenerate}
                            >
                                <h4 style={{marginTop: '4px'}}>{buttonLabel}</h4>
                            </Button>
                        </AnimationWrapper>
                    </LayoutBlock>
                    <Accordion.Collapse eventKey='0'>
                        <LayoutBlock marginTop={1}>
                            <SelectDifficulty { ...props } />
                            <LayoutBlock flexCenter injectStyles={{justifyContent: 'space-evenly'}}>
                                <Checkbox
                                    label='cheese'
                                    onChange={onCheeseSelection}
                                    checked={wantsCheese}
                                />
                                <Checkbox
                                    label='vegan'
                                    onChange={onVeganSelection}
                                    checked={VEGAN}
                                />
                                <Checkbox
                                    label='gluten-free'
                                    onChange={onGlutenSelection}
                                    checked={GLUTEN}
                                />
                            </LayoutBlock>
                        </LayoutBlock>
                    </Accordion.Collapse>
                </Accordion>
            </LayoutBlock>
        )
    }
    return null;
};

export default Options;
