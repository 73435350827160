import { styled } from 'styletron-react';
import {colors} from '../../constants/commonStyles';

export const HomeContainer = styled('div', ({ $injectStyles }) => ({
    overflowX: 'hidden',
    backgroundImage: colors().gradientBackgroundA,
    display: 'flex',
    flexDirection: 'row-reverse',
    ...($injectStyles || {}),
}));

const desktopLeftStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
};

export const HomeLeftContainer = styled('div', ({ $isMobile }) => ({
    overflowX: 'hidden',
    ...(!$isMobile ? desktopLeftStyles : {})
}));

export default {
    HomeContainer,
    HomeLeftContainer
};
