import { dietaryCodes } from '../../constants'

export const WHITE_CORN = {
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    name: "white corn tortillas",
    code: "wct",
    ingredients: [
        {
            name: "white corn tortillas",
            measurement: "1 package"
        }
    ]
};

export const YELLOW_CORN = {
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    name: "yellow corn tortillas",
    code: "yct",
    ingredients: [
        {
            name: "yellow corn tortillas",
            measurement: "1 package"
        }
    ]
};

export const FLOUR = {
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN,
        dietaryCodes.GLUTEN
    ],
    name: "flour tortillas",
    code: "f",
    ingredients: [
        {
            name: "flour tortillas",
            measurement: "1 package"
        }
    ]
};

export const TOSTADAS = {
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    name: "tostadas",
    code: "t",
    ingredients: [
        {
            name: "tostadas",
            measurement: "1 package"
        }
    ]
};

export const TORTILLA = [
    WHITE_CORN,
    WHITE_CORN,
    YELLOW_CORN,
    FLOUR,
    FLOUR,
    TOSTADAS,
];

export default TORTILLA;
