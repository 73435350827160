import React from 'react';
import { Card, LayoutBlock, Gutter } from '..';
import { media } from '../../constants/commonStyles';
import {
    Container,
    RowContainer,
    HeadlinePlaceholder,
    HeadlinePlaceholderAnimationOverlay,
    HeaderPlaceholder,
} from './styles';

const SkeletonLoader = () => {
    const Line = ({ marginTop, height, width, borderradius, marginLeft }) => (
        <HeadlinePlaceholder
            $marginTop={marginTop}
            height={height}
            width={width}
            $borderradius={borderradius}
            $marginLeft={marginLeft}
        >
            <HeadlinePlaceholderAnimationOverlay/>
        </HeadlinePlaceholder>
    );
    return (
        <>
            <HeaderPlaceholder>
                <RowContainer>
                    <Gutter />
                    <Line width='71px' height='16px' marginLeft='10px' />
                    <Line width='40px' height='16px' marginLeft='36px'/>
                    <Line width='69px' height='16px' marginLeft='34px'/>
                    <Gutter />
                </RowContainer>
            </HeaderPlaceholder>
            <Container>
                <LayoutBlock row>
                    <Gutter />
                    <LayoutBlock
                        injectStyles={{maxWidth: media.gutterTrigger, width: '100%'}}
                        marginTop={3}
                    >
                        <Line width='50%'/>
                        <LayoutBlock marginTop={1}/>
                        <Card>
                            <Line width='100%'/>
                            <Line width='80%'/>
                            <Line width='91%'/>
                        </Card>
                        <LayoutBlock marginTop={3}/>
                        <Line width='45%'/>
                        <LayoutBlock marginTop={1}/>
                        <Card>
                            <Line width='40%' height='16px'/>
                            <Line width='40%' height='16px'/>
                            <Line width='50%' height='16px'/>
                            <Line width='30%' height='16px'/>
                            <Line width='50%' height='16px'/>
                            <Line width='44%' height='16px'/>
                            <Line width='50%' height='16px'/>
                            <Line width='55%' height='16px'/>
                            <Line width='50%' height='16px'/>
                            <Line width='33%' height='16px'/>
                            <Line width='55%' height='16px'/>
                            <Line width='40%' height='16px'/>
                            <Line width='45%' height='16px'/>
                        </Card>
                        <LayoutBlock marginTop={3}/>
                        <Line width='30%'/>
                        <LayoutBlock marginTop={1}/>
                        <Card>
                            <Line width='100%'/>
                            <Line width='80%'/>
                            <Line width='100%'/>
                            <Line width='91%'/>
                            <Line width='80%'/>
                            <Line width='70%'/>
                        </Card>
                    </LayoutBlock>
                    <Gutter />
                </LayoutBlock>
            </Container>
        </>
    );
};

export default SkeletonLoader;
