import React from 'react';
import {styled} from 'styletron-react';

const SvgContainer = styled('div', () => {
    return {
        cursor: 'pointer'
    }
});

function BackArrow({size, width, disabled, right, onClick, color, ...props}) {
        const fillColor = color || '#000000';
        return (
            <SvgContainer
                onClick={disabled ? () => {} : onClick}
            >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={size || 150}
                        height={size || 150}
                        viewBox="0 0 15 15"
                        style={right ? {transform: 'rotate(180deg)'} : {}}
                        {...props}
                        color={disabled ? 'silver' : fillColor}
                    >
                        <g stroke={disabled ? 'silver' : fillColor} strokeWidth="0.1" fill={disabled ? 'silver' : fillColor} fillRule="evenodd">
                            <path d="M8.00000525,2 C8.48493622,2 8.87809299,2.39315677 8.87809299,2.87808774 L8.87809299,2.87808774 L8.87831478,11.0021174 L10.7400346,9.14004407 C11.0601057,8.81997294 11.5657387,8.79863487 11.9105514,9.07602984 L11.9105514,9.07602984 L11.9818521,9.14004407 C12.3247434,9.4829354 12.3247434,10.0389702 11.9818521,10.3818615 L11.9818521,10.3818615 L8.62091399,13.7427996 C8.27798064,14.0857329 7.72202986,14.0857329 7.37909964,13.7428027 L7.37909964,13.7428027 L4.01805619,10.3818433 C3.85148499,10.2151355 3.76097945,9.99201326 3.76097945,9.76103682 C3.76097945,9.5300357 3.85150211,9.30685274 4.01815845,9.14012809 C4.36109179,8.79719475 4.91704258,8.79719475 5.25997167,9.14012383 L5.25997167,9.14012383 L7.12207954,11.0014115 L7.12191751,2.87808774 C7.12191751,2.42347447 7.46749347,2.04950428 7.91023501,2.00453384 L7.91023501,2.00453384 Z" id="Combined-Shape" transform="translate(8.000000, 8.000000) rotate(90.000000) translate(-8.000000, -8.000000) " />
                        </g>
                    </svg>
            </SvgContainer>
        );
}

export default BackArrow;

