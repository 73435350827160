import React from 'react';
import { Text } from '@react-pdf/renderer';
import { recipeTitleStringGenerator } from "../../utilities";
import { RecipeText, styles } from './styles';
const {normalText, marginTop} = styles;

const RecipeTitle = ({ recipe, isShortDesktop, hyperbole, conQueso, isDocument }) => {
    return (
        <React.Fragment>
            {
                isDocument ?
                    <Text style={{...normalText, ...marginTop}}>
                        {recipeTitleStringGenerator({recipe, conQueso, hyperbole})}
                    </Text> :
                    <RecipeText $isShortDesktop={isShortDesktop}>
                        {recipeTitleStringGenerator({recipe, conQueso, hyperbole})}
                    </RecipeText>
            }
        </React.Fragment>
    )
};

export default RecipeTitle;
