import React from "react";
import {isMobile, MobileView} from "react-device-detect";
import { scroller } from 'react-scroll';
import Nav from 'react-bootstrap/Nav';
import BackArrow from '../../assets/svgs/BackArrow';
import { media } from "../../constants/commonStyles";
import { LayoutBlock, SocialMediaShare, HeadlineAndCopy, Gutter } from '..';

const TabObject = {
    "link-0": 'home-scroll',
    "link-1": 'base-scroll',
    "link-2": 'toppings-scroll',
};

const onTabChange = (key) => {
    scroller.scrollTo(TabObject[key], {
        duration: 390,
        offset: -80,
        smooth: 'easeInOutQuint',
    });
};

const RecipeSceneHeader = ({onHide, recipe, wantsCheese, hyperbole}) => {
    return (
        <>
            <LayoutBlock
                marginTop={0}
                row
                marginBottom={2}
                injectStyles={{
                    width: isMobile ? '100%' : '50%',
                    display: 'flex',
                    position: 'fixed',
                    zIndex: '10000',
                    backgroundColor: 'white',
                    top: 0,
                    boxShadow: '-6px 0 white, 6px 0 rgba(99,115,129,0.35), 0 2px 5px 0 rgba(99,115,129,0.35)'
                }}>
                <LayoutBlock marginTop={0} row>
                    <Gutter/>
                    <LayoutBlock
                        row
                        marginTop={0}
                        injectStyles={{
                            maxWidth: media.gutterTrigger,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <LayoutBlock marginTop={0}>
                            <MobileView>
                                <BackArrow
                                    color="silver"
                                    size={24}
                                    onClick={onHide}
                                />
                            </MobileView>
                        </LayoutBlock>
                        <LayoutBlock marginTop={0}>
                            <LayoutBlock
                                injectStyles={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                                marginTop={2}>
                                <HeadlineAndCopy
                                    injectStyles={{
                                        marginBottom: '-11px',
                                    }}
                                    marginTop={0}
                                    smallCopy
                                    isSubdued
                                    copy='scroll to section'
                                />
                                <Nav
                                    variant="light"
                                    defaultActiveKey="link-0"
                                    onSelect={selectedKey => onTabChange(selectedKey)}
                                >
                                    <Nav.Link eventKey="link-0">overview</Nav.Link>
                                    <Nav.Link eventKey="link-1">base</Nav.Link>
                                    <Nav.Link eventKey="link-2">toppings</Nav.Link>
                                </Nav>
                            </LayoutBlock>
                        </LayoutBlock>
                        <LayoutBlock
                            marginTop={0}
                            injectStyles={{
                                marginTop: '-17px',
                                cursor: 'pointer',
                                zIndex: 999999,
                        }}
                        >
                            <SocialMediaShare
                                originUrl={`https://taco.ninja/${window.location.search}`}
                                recipe={recipe}
                                conQueso={wantsCheese}
                                hyperbole={hyperbole}
                            />
                        </LayoutBlock>
                    </LayoutBlock>
                    <Gutter/>
                </LayoutBlock>
            </LayoutBlock>
        </>
    )
};

export default RecipeSceneHeader;
