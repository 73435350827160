import React from 'react';
import CategoryImages from '../../assets/svgs/CategoryImages';
import { baseTypes } from '../../constants';
import { images } from '../../utilities';
import { OptionCard } from '..';
import { Container } from './styles';

const OptionCards = ({ value, isShortDesktop, isSelected, onClick, ...props }) => (
    <Container {...props}>
        {
            Object.keys(baseTypes).map((optionItem, i) => (
                <OptionCard
                    isShortDesktop={isShortDesktop}
                    key={i}
                    addTopMargin={i > 3}
                    onClick={onClick}
                    isSelected={optionItem === value}
                    image={<CategoryImages black={optionItem === value} src={images[optionItem]} />}
                    value={optionItem}
                    label={optionItem.toLowerCase()}
                />
            ))
        }
    </Container>
);

export default OptionCards;
