import React from 'react';

import { LayoutBlock } from '..';

export const FooterExtraInfo = () => (
    <LayoutBlock marginTop={0} marginBottom={1}>
        <small id="silver">created by reed black</small>
            <LayoutBlock marginTop={0}>
                <small id='silver'>&copy; 2021 all rights reserved</small>
            </LayoutBlock>
        <LayoutBlock marginTop={0}>
            <small id='silver'>
                <a target="_blank" rel="noopener noreferrer" href="mailto:contact.taconinja@gmail.com">reach out </a>
                for partnerships, recipe submissions, or anything!
            </small>
        </LayoutBlock>
        <LayoutBlock marginTop={0}>
            <small>
                <a target="_blank" rel="noopener noreferrer" href="https://reedblack.io">reedblack.io</a>
            </small>
        </LayoutBlock>
        <LayoutBlock marginTop={0}>
            <small id='silver'>
                Icons made by
                <a target="_blank" rel="noopener noreferrer" href="https://flaticon.com/authors/freepik"> Freepixel </a>
                and
                <a target="_blank" rel="noopener noreferrer" href="https://flaticon.com/authors/monkik"> monkik </a>
                from
                <a target="_blank" rel="noopener noreferrer" href="https://flaticon.com"> www.flaticon.com</a>
            </small>
        </LayoutBlock>
    </LayoutBlock>
);

export default FooterExtraInfo;
