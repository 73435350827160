import {styled} from 'styletron-react';
import { media, unit, colors } from '../../constants/commonStyles';

const difficultyMapping = {
    1: colors().quinaryLight,
    2: colors().tertiaryLight,
    3: colors().primaryLight,
    4: colors().secondaryLight,
    5: colors().quaternaryLight,
};

const floats = {
    float: 'right',
};

const noFloat = {
    marginLeft: 'none',
};

const forceRight = {
    marginRight: '-48px',
};

export const DifficultyContainer = styled('div', ({ $difficulty, $floats, $forceRight }) => ({
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5px',
    paddingLeft: unit[1],
    paddingRight: unit[1],
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    maxWidth: '260px',
    marginLeft: unit[1],
    marginRight: '-18px',
    marginTop: '-16px',
    maxHeight: '30px',
    backgroundColor: difficultyMapping[$difficulty],
    [`@media (max-width: ${media.mobileMaxWidth})`]: {
        maxHeight: '40px',
        marginLeft: 'none',
    },
    ...$floats ? floats : {},
    ...!$floats ? noFloat : {},
    ...$forceRight ? forceRight : {},

}));

export default DifficultyContainer;
