import React from 'react';
import { HeadlineAndCopy } from '..';
import { Container, OuterContainer } from './styles';

const Icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height={'1.1rem'}
        width={'1.1rem'}
        viewBox="0 0 150 150"
    >
        <g>
            <g fill="#FFFFFF" >
                <path d="M145.979415,19.0295564 C140.613773,13.5706553 131.920219,13.5706553 126.554823,19.0295564 L44.8599185,102.14794 L23.1373062,83.1628538 C17.8451937,77.7782642 9.26585378,77.7782642 3.96908441,83.1628538 C-1.32302814,88.547194 -1.32302814,97.280538 3.96908441,102.664878 L36.4227342,131.026177 C41.7148468,136.410767 50.2941867,136.410767 55.590956,131.026177 C56.122079,130.486048 56.566193,129.885072 56.9919247,129.284346 C57.0696201,129.209785 57.1566292,129.158416 57.2345697,129.083855 L145.97966,38.7876811 C151.340154,33.333518 151.340154,24.4837194 145.979415,19.0295564 L145.979415,19.0295564 Z" />
            </g>
        </g>
    </svg>
);

const Checkbox = ({ onChange, checked, label }) => (
    <OuterContainer>
        <Container
            $isSelected={checked}
            onClick={() => onChange(checked = !checked)}
        >
            { checked ? <Icon /> : null}
        </Container>
        <HeadlineAndCopy
            injectStyles={{marginLeft: '6px', marginBottom: '2px'}}
            copy={label} />
    </OuterContainer>
);

export default Checkbox;

