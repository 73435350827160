import React from 'react';

function Caret({ color, height, width, rotate, ...props }) {

    const rootStyles = { transition: '0.5s' };
    const rotateStyle = () => {
        switch (rotate) {
            case 'DOWN': {
                return {
                    ...rootStyles,
                    transform: 'rotate(-90deg)',
                };
            }

            case 'UP': {
                return {
                    ...rootStyles,
                    transform: 'rotate(90deg)',
                };
            }

            case 'LEFT': {
                return {
                    ...rootStyles,
                    transform: 'rotate(0deg)',
                };
            }

            case 'RIGHT': {
                return {
                    ...rootStyles,
                    transform: 'rotate(180deg)',
                };
            }

            default: return rootStyles;
        }
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 150}
            height={height || 150}
            viewBox="0 0 150 150"
            style={rotateStyle()}
            {...props}
        >
            <path
                d="M36 82C32 78 32 72 36 68L101 3C104-1 110-1 114 3 118 7 118 13 114 16L56 75 114 134C118 137 118 143 114 147 112 149 110 150 107 150 105 150 102 149 101 147L36 82Z"
                fill="silver"
            />
        </svg>
    );
}

export default Caret;
