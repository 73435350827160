import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import PopoverContent from 'react-bootstrap/PopoverContent'
import PopoverTitle from 'react-bootstrap/PopoverTitle'
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from 'react-share';
import { recipeTitleStringGenerator } from "../../utilities";
import { LayoutBlock } from '..';
import Share from '../../assets/svgs/Share';

const SocialMediaShare = ({ originUrl, recipe, hyperbole, conQueso }) => {
    const fullString = `I rolled the dice with taconinja and all I got were these ${recipeTitleStringGenerator({recipe, conQueso, hyperbole})}`;
    const popover = (
        <Popover style={{zIndex: 999999999}}>
            <PopoverTitle><h4>share recipe</h4></PopoverTitle>
            <PopoverContent>
                <FacebookShareButton
                    url={originUrl}
                    quote={fullString}
                >

                    <FacebookIcon size={32} />
                </FacebookShareButton>
                <LayoutBlock marginTop={1} />
                <TwitterShareButton
                    url='taco.ninja'
                    title='I turnt up my taco night with taconinja, the greatest taco recipe generator in the history of the multiverse...ever.'
                    hashtags={['taconinja', 'taco-night', 'tacos']}
                >
                    <TwitterIcon size={32} />
                </TwitterShareButton>
            </PopoverContent>
        </Popover>
    );

    return (
        <LayoutBlock>
            <OverlayTrigger trigger='click'  placement='left' overlay={popover}>
                <Share />
            </OverlayTrigger>
        </LayoutBlock>
    )
};

export default SocialMediaShare;
