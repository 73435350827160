import React from 'react';
import { Page, Text, View, Image, Document } from '@react-pdf/renderer';
import { styles } from './styles';
import {RecipeTitle} from '..';
import {combineToppings} from '../../utilities/combineToIngredientsArray';
const tacoLogo = require('../../assets/logo_wide.png');

const {
    smallImage,
    flexRow,
    page,
    header,
    smallText,
    marginTop,
    normalText,
    baseSection,
    divider,
} = styles;

const AdditionalTopping = ({ recipe }) => (
    recipe.additionalTopping ? (
        <>
            <View style={marginTop}/>
            <Text style={normalText}>an extra option...</Text>
            <Text style={normalText}>{recipe.additionalTopping.name}</Text>
            <Text style={smallText}>{recipe.additionalTopping.description}</Text>
            <View style={marginTop}/>

            {
                recipe.additionalTopping.ingredients.map((ingredient, i) => {
                    return (
                        <Text style={smallText} key={i}>
                            - {ingredient.measurement} {ingredient.name}
                        </Text>
                    )
                })
            }
            <View style={marginTop}/>
            <Text style={normalText}>instructions:</Text>
            {
                recipe.additionalTopping.preparation.map((step) => {
                    return (<Text style={smallText} key={step}>- {step}</Text>)
                })
            }
        </>
    ) : null
);

const RecipePDF = ({recipe, hyperbole, conQueso}) => {

    const Header = () => (
        <View style={header}>
            <Text style={{...smallText, marginLeft: '159px'}}>This recipe document has been immaculately conceived
                by</Text>
            <View style={{...flexRow, ...marginTop}}>
                <Image alt="taco ninja taco recipes" style={{...smallImage, marginLeft: '247px'}} src={tacoLogo}/>
            </View>
            <RecipeTitle isDocument recipe={recipe} hyperbole={hyperbole} conQueso={conQueso}  />
        </View>
    );

    const BaseRecipeSection = () => (
        <View style={baseSection}>
            <Text style={normalText}>your {recipe.base.name} base</Text>
            <Text style={smallText}>{recipe.base.description}</Text>
            <View style={marginTop}/>
            <Text style={normalText}>ingredients:</Text>
            {
                recipe.base.ingredients.map((ingredient) => {
                    return (
                        <Text style={smallText} key={ingredient.name}>
                            - {ingredient.measurement} {ingredient.name}
                        </Text>
                    )
                })
            }
            <View style={marginTop}/>
            <Text style={normalText}>instructions:</Text>
            {
                recipe.base.preparation.map((step, i) => {
                    return (
                        <Text style={smallText} key={i}>
                            - {step}
                        </Text>
                    )
                })
            }
            {
                recipe.mixin ? (
                    <View style={marginTop}>
                        <Text style={normalText}>To keep your {recipe.base.name} interesting, consider mixing it up with {recipe.mixin.name} after it's prepared</Text>
                        <View style={marginTop}/>
                        <Text style={normalText}>ingredients for your {recipe.base.name} enhancer</Text>
                            {
                                recipe.mixin.ingredients.map((ingredient, i) => {
                                    return (
                                        <Text style={smallText} key={i}>
                                            - {ingredient.measurement} {ingredient.name}
                                        </Text>
                                    )
                                })
                            }
                        <Text style={normalText}>instructions:</Text>
                        <Text style={smallText}>{recipe.mixin.preparation} Stir in or toss any amount with the {recipe.base.name} base before serving. How much? Trust yourself. We believe in you!</Text>
                    </View>
                ) : null
            }
        </View>
    );

    const ToppingsSection = () => (
        <View style={{...baseSection, marginLeft: 16}}>
            {
                (recipe.topping || conQueso) ?
                    <React.Fragment>
                        {recipe.topping ?
                            <React.Fragment>
                                <Text style={normalText}>a topping of {recipe.topping.name}</Text>
                                <Text style={smallText}>{recipe.topping.description}</Text>
                            </React.Fragment> : null
                        }
                        <View style={marginTop}/>
                        <Text style={normalText}>ingredients:</Text>
                        {
                            combineToppings(recipe, conQueso).map((ingredient, i) => {
                                return (
                                    <Text style={smallText} key={i}>
                                        - {ingredient.measurement} {ingredient.name}
                                    </Text>
                                )
                            })
                        }
                        <View style={marginTop}/>
                        <Text style={normalText}>instructions:</Text>
                        {   recipe.topping ?
                            recipe.topping.preparation.map((step, i) => {
                                return (
                                    <Text style={smallText} key={i}>
                                        - {step}
                                    </Text>
                                )
                            }) : null
                        }
                    </React.Fragment> : null}
            {
                recipe.garnish ? (
                    <React.Fragment>
                        <View style={marginTop}/>
                        <Text style={normalText}>Let's give it some color. Add some {recipe.garnish.name}</Text>
                        <View style={marginTop}/>
                        {
                            recipe.garnish.ingredients.map((ingredient, i) => {
                                return (
                                    <Text style={smallText} key={i}>
                                        - {ingredient.measurement} {ingredient.name}
                                    </Text>
                                )
                            })
                        }

                    </React.Fragment>
                ) : null
            }
            {
                (conQueso && recipe.queso) ? (
                    <>
                        <View style={marginTop} >
                        <Text style={normalText}>for your cheese...</Text>
                            <Text style={smallText}>crumble, grate, spread, melt, smother, explode, or inject some {recipe.queso.name} into, onto, or around your creation</Text>
                            <Text style={smallText}>listen carefully to your cheese. How does it want to be involved with your taco?</Text>
                        </View>
                    </>
                ) :
                    <>
                        <AdditionalTopping recipe={recipe}/>
                    </>
            }
        </View>

    );

    return (
        <Document>
            <Page size="A4" style={page}>
                <Header/>
                <View style={{...flexRow, justifyContent: 'spaceBetween'}}>
                    <BaseRecipeSection/>
                    <View style={divider} />
                    <ToppingsSection/>
                </View>
            </Page>
        </Document>
    );
};

export default RecipePDF;
