import { styled } from 'styletron-react';
import { helpers, unit, media } from '../../constants/commonStyles.js';

const extraInfoStyles = {
    display: 'flex',
    paddingLeft: unit[2],
    paddingRight: unit[2],
    alignItems: 'none',
    justifyContent: null,
    flexDirection: 'column',
};

export const FooterContainer = styled('div', ({$extraInfo}) => ({
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingBottom: unit[1],
    zIndex: '1000000',
    width: '100%',
    minHeight: '4vh',
    height: '100%',
    borderRight: "none",
    boxShadow: '0 2px 5px 0 rgba(99,115,129,0.35)',
    ...helpers.flexVerticalContainer,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: unit[6],
    paddingRight: unit[6],
    backgroundColor: 'white',
    [`@media (max-width: ${media.mobileMaxWidth})`]: {
        paddingLeft: unit[2],
        paddingRight: unit[2],
    },
    ...$extraInfo ? extraInfoStyles : {},
}));

export default {
    FooterContainer,
};
