import {WHIPPED_COCONUT_CREAM, SRIRACHA_MAYO, THAI_PEANUT_SAUCE} from '../standard/additionalToppings';
// import {  } from '../standard/garnish';
import { CHOPPED_MANGO, CHOPPED_PINEAPPLE } from '../standard/mixins';
// import {  } from '../standard/queso';
import { CHIPOTLE_PINEAPPLE_SAUCE, LIME_SLAW, MANGO_GUAC } from '../standard/toppings';
// import {  } from '../standard/tortilla';

const CARIBBEAN = {
    toppings: [CHIPOTLE_PINEAPPLE_SAUCE, LIME_SLAW, MANGO_GUAC],
    additionalToppings: [WHIPPED_COCONUT_CREAM, SRIRACHA_MAYO, THAI_PEANUT_SAUCE],
    garnishes: [],
    mixins: [CHOPPED_MANGO, CHOPPED_PINEAPPLE],
    queso: [],
    tortilla: []
};

export default CARIBBEAN;
