import dietaryCodes from '../constants/dietaryCodes';

export const dietaryFilter = (recipes, dietaryRestrictions) => {
    try {
        let filtered;
        if (dietaryRestrictions.GLUTEN === true) {
            filtered = recipes.filter(recipe => {
                return !recipe.dietaryCodes.includes(dietaryCodes.GLUTEN);
            });
        }
        if (dietaryRestrictions.VEGAN === true) {
            filtered = (filtered || recipes).filter(recipe => {
                return recipe.dietaryCodes.includes(dietaryCodes.VEGAN);
            });
        }
        return filtered || recipes;
    } catch (e) {
        return recipes;
    }
};

export default dietaryFilter;
