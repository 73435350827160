const recipeTitleStringGenerator = ({recipe, conQueso, hyperbole}) => {
    const getToppingWithComma = recipe.additionalTopping ? recipe.topping.name : `${recipe.topping.name},`;
    const additionalToppingString = recipe.additionalTopping ? `${conQueso ? ', ' : ' and '} ${recipe.additionalTopping.name},` : '';
    const quesoString = conQueso ? ` and ${recipe.queso.name},` : '';
    const mixinString = recipe.mixin ? ` tossed with ${recipe.mixin.name},` : '';
    const toppingString = ` topped with ${getToppingWithComma}`;
    const garnishString = recipe.garnish ? ` garnished with ${recipe.garnish.name},` : '';

    return `${hyperbole} ${recipe.base.name} tacos ${mixinString} ${toppingString}${additionalToppingString}${quesoString}
            ${garnishString} served on ${recipe.tortilla.name}.`;
};

export {
    recipeTitleStringGenerator,
}
