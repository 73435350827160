import React from 'react';
import { Title, Gutter, } from '..';
import { HeaderContainer } from './styles';

const Header = () => (
    <HeaderContainer>
        <Gutter/>
        <Title/>
        <Gutter/>
    </HeaderContainer>
);

export default Header;
