const searchParams = new URLSearchParams(window.location.search);
const colorScheme = searchParams.get('scheme') || 'DEFAULT';

export const unit = {
	0: '0px',
	1: '8px',
	2: '16px',
	3: '24px',
	4: '48px',
	5: '64px',
	6: '128px',
};



// dimensions / measurements
export const media = {
	mobileMaxWidth: '768px',
	desktopMinWidth: '769px',
	wideWidth: '1200px',
	midWidth: '1024px',
	gutterTrigger: '725px',
};

export const borderSmoothing = {
	borderRadius: '4px',
};

// https://coolors.co/006ba6-0496ff-ffbc42-d81159-8f2d56
const colorSchemeA = {
	// light quinacridone magenta
	lightBackground: '#F0D0DE',
	// dark sapphire blue
	darkBackground: '#001B29',

	gradientBackgroundA: 'linear-gradient(to bottom right, #EBF7FF, #FDECF2)',
	gradientBackgroundB: 'linear-gradient(to bottom right, #8F2D56, #006BA6)',

	// Maximum Yellow Red
	primary: '#FFBC42',
	primaryDark: '#CC8500',
	primaryExtraDark: '#291B00',
	primaryLight: '#FFF8EB',

	// Quinacridone Magenta
	secondary: '#8F2D56',
	secondaryLight: '#F0D0DE',
	secondaryDark: '#1F0A13',

	// Sapphire Blue
	tertiary: '#006BA6',
	tertiaryLight: '#D6F1FF',
	tertiaryDark: '#00507a',

	// Ruby
	quaternary: '#D81159',
	quaternaryLight: '#FDECF2',
	quaternaryDark:'#260310',

	// Dodger Blue
	quinary: '#0496FF',
	quinaryLight: '#EBF7FF',
	quinaryDark: '#001829',
};

const commonColors = {
	white: '#FFFFFF',
	glassBackgroundA: 'linear-gradient(110deg, rgba(255, 255, 255, 0.1) 0%, rgb(120 120 120 / 10%) 80%)',
	glassBackgroundB: 'linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgb(120 120 120 / 30%) 100%)',
	backdropFilter: 'blur(10px)',
	glassBorder: '1px solid rgb(255 255 255 / 10%)',
};

export const colors = () => {
	switch(colorScheme){
		case 'A':
			return {
				...colorSchemeA,
				...commonColors
			};
		case 'B':
			return {
				lightBackground: '',
				darkBackground: '',
				gradientBackgroundA: '',
				gradientBackgroundB: '',
				primary: '',
				primaryDark: '',
				primaryLight: '',
				secondary: '',
				tertiary: '',
				quaternary: '',
				quinary: '',
				senary: ''
			};
		default:
			return {
				...colorSchemeA,
				...commonColors
			};
	}
};

export const shadow = {
	boxShadow: '0 2px 5px 0 rgba(99,115,129,0.35)'
};

// Helpers
export const helpers = {
	flexVerticalCenter: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		textAlign: 'center',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	flexHorizontalContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
	},
	flexVerticalContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
	},
	flexRowWrapContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
    }
};

    export const animation = {
		keyframes: {
			colorChanges: {
				"0%": { borderColor: colors().tertiary },
				"50%": { borderColor: colors().quaternary },
				"100%": { borderColor: colors().quinary }
			},
			fillColorChanges: {
				"0%": { fill: colors().tertiary },
				"50%": { fill: colors().quaternary },
				"100%": { fill: colors().quinary }
			},
			wipe: {
				'100%': { transform: 'translateX(100%)' }
			},
			spin: {
				"0%" : {transform: 'rotate(0deg)'},
				"100%" : {transform: 'rotate(360deg)'}
			},
			spinReverse: {
				"0%" : {transform: 'rotate(360deg)'},
				"100%" : {transform: 'rotate(0deg)'}
			}
		},
		settings: {
			continuousEase: {
				animationDuration: '3s',
				animationDirection: 'alternate',
				animationIterationCount: 'infinite',
				animationTimingFunction: 'ease-in-out',
				animationFillMode: 'none',
			},
			wipe: {
				animationDuration: '3.4s',
				animationIterationCount: 'infinite',
			}
		}
	};

export const loadingKeyframes = {
	'100%': { transform: 'translateX(100%)' },
};

export const loadingSettings = {
	animationDuration: '.8s',
	animationIterationCount: 'infinite',
};

export default {
	unit,
	animation,
	helpers,
	media,
	borderSmoothing,
	shadow,
	loadingSettings,
	loadingKeyframes,
};

