import React from 'react';
import { styled } from 'styletron-react';

const QuestionContainer = styled('div', () => ({
    cursor: 'pointer',
}));

function Question({ color, height, width, onClick }) {
    return (
        <QuestionContainer onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 45.395 45.395"
                width={width || 18}
                height={height || 18}
                enableBackground="0 0 45.395 45.395"
            >
                <path fill="silver" d="M22.698,0C10.181,0,0,10.183,0,22.697c0,12.516,10.182,22.697,22.698,22.697c12.516,0,22.696-10.183,22.696-22.697    S35.214,0,22.698,0z M22.698,40.447c-9.787,0-17.749-7.963-17.749-17.75c0-9.787,7.962-17.749,17.749-17.749    c9.786,0,17.747,7.962,17.747,17.749C40.445,32.484,32.484,40.447,22.698,40.447z"/>
                <path
                    fill="silver"
                    d="M22.683,16.977c-2.028,0-3.465,1.646-3.465,3.672v12.485c0,2.028,1.437,3.671,3.465,3.671    c2.027,0,3.463-1.643,3.463-3.671V20.649C26.146,18.621,24.71,16.977,22.683,16.977z"
                />
                <path
                    fill="silver"
                    d="M22.698,7.432c-2.095,0-3.795,1.699-3.795,3.795c0,2.095,1.7,3.793,3.795,3.793c2.093,0,3.793-1.698,3.793-3.793    C26.491,9.131,24.791,7.432,22.698,7.432z"
                />
            </svg>
        </QuestionContainer>
    );
}

export default Question;
