export function combineIngredients(recipe, wantsCheese) {
    return recipe.base.ingredients
        .concat(recipe.mixin ? recipe.mixin.ingredients : null)
        .concat(recipe.garnish ? recipe.garnish.ingredients : null)
        .concat(wantsCheese ? recipe.queso.ingredients : null)
        .concat(recipe.topping ? recipe.topping.ingredients : null)
        .concat(recipe.additionalTopping ? recipe.additionalTopping.ingredients : null)
        .filter(ingredient => (ingredient != null))
}

export function combineToppings(recipe) {
    const starterArr = [];
    return starterArr
        .concat(recipe.topping ? recipe.topping.ingredients : null)
        .filter(ingredient => (ingredient != null))
}
