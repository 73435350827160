import { styled } from 'styletron-react';
import { unit, media } from '../../constants/commonStyles';

export const Container = styled('div', () => ({
    width: '100%',
    paddingTop: unit[1],
    maxWidth: media.gutterTrigger,
    minHeight: '1175px',
    overflow: 'scroll',
}));

export default {
    Container
};
