import React from 'react';
import { LayoutBlock, InspirationalQuote } from '..';
import Logo from '../Logo';
import { Container } from './styles';

const RecipePlaceholder = ({brand, welcomeMessage}) => (
    <React.Fragment>
        <Container>
            <InspirationalQuote welcomeMessage={welcomeMessage} />
            <LayoutBlock flexCenter marginTop={6}>
                <Logo overlayOpacity={.70} size={180} />
            </LayoutBlock>
        </Container>
    </React.Fragment>
);

export default RecipePlaceholder;
