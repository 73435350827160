import React from 'react';
import { TitleContainer, TitleImage } from './styles';

const tacoTitle = require("../../assets/logo_wide.png");
const Title = ({ small }) => (
    <TitleContainer $small={small}>
        <TitleImage src={tacoTitle}/>
    </TitleContainer>
);

export default Title;
