import React from "react";
import { Container } from './styles';
import BackArrow from '../../assets/svgs/BackArrow';
import { colors } from '../../constants/commonStyles';

import {HeadlineAndCopy, LayoutBlock} from '..';

export const NavigationButtons = ({currentRecipeIndex, recipeStore, onRecipeNavigate, isProcessing}) => {
    const BackButton = () => {
        if (currentRecipeIndex >= 0 && recipeStore.length >= 2) {
            return (
                <div>
                    <BackArrow
                    size={26}
                    color={colors().secondary}
                    disabled={isProcessing}
                    onClick={() => onRecipeNavigate('BACK')
                    }/>
                    <HeadlineAndCopy
                        injectStyles={{
                            marginTop: '-11px',
                        }}
                        marginTop={0}
                        smallCopy
                        isSubdued
                        copy='previous'
                    />
                </div>
                )
        }
        return null
    };
    const LatestButton = () => {
        if (
            (currentRecipeIndex !== recipeStore.length - 2 &&
                currentRecipeIndex !== recipeStore.length - 1
            )
            && recipeStore.length >= 2
        ) {
            return (
                <LayoutBlock
                    marginTop={0}
                    injectStyles={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                    }}
                >
                    <BackArrow
                        color={colors().tertiary}
                        size={26}
                        right
                        disabled={isProcessing}
                        onClick={() => onRecipeNavigate('LATEST')
                        }/>
                    <HeadlineAndCopy
                        injectStyles={{
                            marginTop: '-11px',
                        }}
                        marginTop={0}
                        smallCopy
                        isSubdued
                        copy='latest'
                    />
                </LayoutBlock>
            )
        }
        return null
    };
    return (
        <Container>
            <BackButton />
            <LayoutBlock horizontalSpacer />
            <LatestButton />
        </Container>
    )
};

export default NavigationButtons;
