import React from 'react';
import Select from 'react-select';
import { difficulties } from '../../constants';
import { LayoutBlock, HeadlineAndCopy } from '..';

const difficultyLabelMap = {
    [difficulties.ANY]: 'any',
    [difficulties.EASY]: 'white belt',
    [difficulties.MODERATE]: 'black belt',
    [difficulties.ADVANCED]: 'ninja',
};

const options = Object.keys(difficulties).map(difficulty => {
    return {
        value: difficulty,
        label: difficultyLabelMap[difficulty],
    }
});

const sharedCustomStyles = {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    marginTop: '-3px',
    lineHeight: 1.1,
    fontWeight: '600',
};

const customStyles = {
    option: (provided) => ({
        ...sharedCustomStyles,
        ...provided,
    }),
    control: (provided) => ({
        ...provided,
        ...sharedCustomStyles,
    })
};

const SelectDifficulty = ({selectedDifficulty, onDifficultySelect}) => (
    <LayoutBlock marginTop={0}>
        <HeadlineAndCopy copy="difficulty" />
        <Select
            styles={customStyles}
            isSearchable={false}
            value={selectedDifficulty || difficulties.ANY}
            onChange={onDifficultySelect}
            options={options}
        />
    </LayoutBlock>
);

export default SelectDifficulty;
