import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { MobileView } from "react-device-detect";
import Caret from '../../assets/svgs/Caret';
import Question from '../../assets/svgs/Question';
import { HeadlineAndCopy } from '..';
import { CaretContainer, Container, ExtraInfoContainer, InfoContainer, TopContainer } from './styles';

const Card = ({ onClick, isShortDesktop, extraInfo, children, flexCenter, injectStyles }) => (
    <Container $injectStyles={injectStyles} $isShortDesktop={isShortDesktop} onClick={onClick}>
        <Accordion>
            <TopContainer
                $injectStyles={injectStyles}
                $extraInfo={extraInfo}
                onClick={onClick}
                $flexCenter={flexCenter}>
                {children}
                <MobileView>
                    <CaretContainer onClick={onClick}>
                        <HeadlineAndCopy isSubdued copy='go to recipe' smallCopy />
                        <Caret rotate='RIGHT' width={17} height={17} />
                    </CaretContainer>
                </MobileView>
                <InfoContainer $extraInfo={extraInfo}>
                    <Accordion.Toggle as={Question} variant='link' eventKey='0' />
                </InfoContainer>
            </TopContainer>
            <Accordion.Collapse eventKey="0">
                <ExtraInfoContainer>
                    {extraInfo}
                </ExtraInfoContainer>
            </Accordion.Collapse>
        </Accordion>
    </Container>
);

export default Card;
