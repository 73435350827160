import dietaryHandler from './dietaryHandler';
import difficultyFilter from './difficultyHandler';
import nullifyWithPercentage from './oddsGenerator';
import pairingHandler from './pairingHandler';
import randomize from './randomizer';

import {
    AMERICAN,
    BBQ,
    CARIBBEAN,
    GREEK,
    INDIAN,
    JAPANESE,
    KOREAN,
    MEXICAN,
    MIDDLE_EASTERN,
    SOUTH_AMERICAN,
    SOUTHEAST_ASIAN,
    SPANISH,
} from '../recipes';

const recipeProfileMapping = {
    AMERICAN,
    BBQ,
    CARIBBEAN,
    GREEK,
    INDIAN,
    JAPANESE,
    KOREAN,
    MEXICAN,
    MIDDLE_EASTERN,
    SOUTH_AMERICAN,
    SOUTHEAST_ASIAN,
    SPANISH,
};

export const recipeComponentGenerator = (recipes, {
    baseProfile,
    dietaryRestrictions,
    difficulty,
    nullPercentage,
    optionButtonsValue,
    recipeType
}) => {
    const filteredAndCompoundedRecipes = pairingHandler(recipes, optionButtonsValue);
    const profileRecipes = recipeType === 'base' ? [] : recipeProfileMapping[baseProfile][recipeType];
    const appendedRecipes = recipeType === 'base' ? filteredAndCompoundedRecipes : filteredAndCompoundedRecipes.concat(profileRecipes);
    const filtered = difficultyFilter(dietaryHandler(appendedRecipes, dietaryRestrictions), difficulty);
    return nullifyWithPercentage(randomize(filtered), nullPercentage);
};
