import React from 'react';

import {combineToppings} from '../../utilities/combineToIngredientsArray';

import Card from '../Card/index';
import LayoutBlock from '../LayoutBlock/index';

const ToppingsTab = ({ recipe, wantsCheese, ref }) => {
    const toppingName = `...and the goodness of ${recipe.topping ? recipe.topping.name : ""}
    ${(recipe.topping && wantsCheese) ? 'and' : ""} ${wantsCheese ? recipe.queso.name : ""} shall rain down from the heavens`;
    return (
        <React.Fragment>
            <LayoutBlock marginTop={0}>
                <LayoutBlock marginTop={0}>
                    <h3 ref={ref}>your toppings:</h3>
                    <LayoutBlock marginTop={0}>
                            <Card>
                                <h3>{toppingName}</h3>
                                {recipe.topping ? <p>{recipe.topping.description}</p> : null}
                                <hr/>
                                <h3>{recipe.topping ? recipe.topping.name : null} ingredient{(combineToppings(recipe).length > 1) ? 's' : ''}</h3>
                                <ul>
                                    {
                                        combineToppings(recipe, wantsCheese).map((ingredient, i) => {
                                            return (
                                                <li key={i}>
                                                    {ingredient.measurement} {ingredient.name}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                {recipe.topping ? (
                                    <>
                                        <hr/>
                                        <h3>instructions:</h3>
                                        <ol>
                                            {
                                                recipe.topping.preparation.map((step, i) => {
                                                    return (
                                                        <li key={i}>
                                                            {step}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ol>
                                    </>
                                ) : null}
                            </Card>
                    </LayoutBlock>
                </LayoutBlock>

                <LayoutBlock horizontalSpacer={2} />
                {
                    recipe.garnish ? (
                        <LayoutBlock marginTop={3}>
                            <Card>
                                <h3>let's give it some more color. Add some {recipe.garnish.name}</h3>
                                <hr/>
                                <ul>
                                    {
                                        recipe.garnish.ingredients.map((ingredient, i) => {
                                            return (
                                                <li key={i}>
                                                    {ingredient.measurement} {ingredient.name}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </Card>
                        </LayoutBlock>
                    ) : null
                }
            </LayoutBlock>
            <LayoutBlock horizontalSpacer={2} />
            <LayoutBlock marginTop={0}>
                {
                    (wantsCheese && recipe.queso) ? (
                        <>
                            <LayoutBlock marginTop={0}>
                                <h4>oh, queso...</h4>
                                <Card setWidthDesktop={80} >
                                    <h3>crumble, grate, spread, melt, smother, explode, or inject some {recipe.queso.name} into,
                                        onto, or around your creation</h3>
                                    <hr/>
                                    <h4>listen carefully to your cheese. How does it <i>want</i> to be involved with your taco?
                                    </h4>
                                </Card>
                            </LayoutBlock>
                        </>
                    ) : null
                }
                {
                    recipe.additionalTopping ? (
                        <>
                            <LayoutBlock marginTop={0}>
                                <h4>and a little {recipe.additionalTopping.name} on-top for kicks</h4>
                                <Card>
                                    <p>{recipe.additionalTopping.description}</p>
                                    <hr/>
                                    <h3>{recipe.additionalTopping.name} ingredient{(recipe.additionalTopping.ingredients.length > 1) ? 's' : ''}</h3>
                                    <ul>
                                        {
                                            recipe.additionalTopping.ingredients.map((ingredient, i) => {
                                                return (
                                                    <li key={i}>
                                                        {ingredient.measurement} {ingredient.name}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <hr/>
                                    <h3>instructions:</h3>
                                    <ol>
                                        {
                                            recipe.additionalTopping.preparation.map((step, i) => {
                                                return (
                                                    <li key={i}>
                                                        {step}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ol>
                                </Card>
                            </LayoutBlock>
                        </>
                    ) : null
                }
                <LayoutBlock horizontalSpacer={2} />
                <LayoutBlock marginTop={3} injectStyles={{width: '100%'}}>
                    <Card
                        extraInfo={
                            <>
                                <hr/>
                                <p>We don't wish to presume to tell you how to finish your
                                    taco. We recognize this is often a sensitive topic, potentially involving tribal loyalties.
                                    Perhaps a few suggestions to nudge you in the right direction...</p>
                                <hr/>
                                <h4>the mainstays:</h4>
                                <ul>
                                    <li>fresh cilantro</li>
                                    <li>a squeeze of fresh lime</li>
                                    <li>vinegar-based hot sauce (Tapatio, Cholula, Tabasco, etc)</li>
                                    <li>chili-based hot sauce (basically Sriracha)</li>
                                    <li>cheese, sour cream, or anything you love that didn't end up on your recipe</li>
                                </ul>
                                <hr/>
                                <h4>a note on being creative with it:</h4>
                                <p>Does your taco recipe have distinctly Mediterranean vibes? If so, perhaps try finishing with a bit of Italian parsley and a gentle squeeze of lemon. Is your taco turning Japanese? Do you really think so? Try finishing with a pinch of sesame seeds. Your taco may be just perfect as-is, but looking for regional cues in your recipe can give you the opportunity to use your own creativity to tie it all together with your own finishing touch.</p>
                            </>
                        }
                    >
                        <h3>a word about finishes...</h3>
                    </Card>
                </LayoutBlock>
            </LayoutBlock>
        </React.Fragment>
    )
}

export default ToppingsTab;
