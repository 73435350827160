import React from 'react';
import { difficulty as difficultyMapping } from '../../utilities/difficultyMapping';
import DifficultyContainer from './styles';

const Difficulty = ({difficulty, floats, children, forceRight}) => (
    <DifficultyContainer
        $difficulty={difficulty}
        $floats={floats}
        $forceRight={forceRight}
    >
        <p>difficulty: {difficultyMapping(difficulty)}</p>
    </DifficultyContainer>
);

export default Difficulty;
