import React from 'react';
import { styled } from 'styletron-react';
import { borderSmoothing } from "../../constants/commonStyles";

const QuestionContainer = styled('div', () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    ...borderSmoothing,
}));

function CategoryImages({ color, src, isSelected, black, paths, height, width, onClick }) {
    return (
        <QuestionContainer onClick={onClick}>
            <img alt='taconinja taco recipes' src={src} height={77} width={77} />
        </QuestionContainer>
    );
}

export default CategoryImages;
