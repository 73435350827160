import React from 'react';

import Card from '../Card/index';
import LayoutBlock from '../LayoutBlock/index';

export function BaseTab({recipe, ref}) {

    return (
        <React.Fragment>
            <LayoutBlock marginTop={0}>
                <LayoutBlock marginTop={0}>
                    <h3 ref={ref}>your taco base:</h3>
                    <Card name='base-scroll' id="containerElement">
                        <h3>{recipe.base.name} shall be the rock upon which you build your taco</h3>
                        <p>{recipe.base.description}</p>
                        <hr/>
                        <h3>ingredients for this base recipe:</h3>
                        <ul>
                            {
                                recipe.base.ingredients.map((ingredient, i) => {
                                    return (
                                        <li key={i}>
                                            {ingredient.measurement} {ingredient.name}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <hr/>
                        <h3>instructions:</h3>
                        <ol>
                            {
                                recipe.base.preparation.map((step, i) => {
                                    return (
                                        <li key={i}>
                                            {step}
                                        </li>
                                    )
                                })
                            }
                        </ol>
                    </Card>
                </LayoutBlock>
                <LayoutBlock horizontalSpacer={2} />
                {
                    recipe.mixin ? (
                        <LayoutBlock marginTop={3}>
                            <Card>
                                <h3>...and some {recipe.mixin.name} to jazz it up</h3>
                                <hr/>
                                <h3>ingredients for your {recipe.base.name} enhancer</h3>
                                <ul>
                                    {
                                        recipe.mixin.ingredients.map((ingredient, i) => {
                                            return (
                                                <li key={i}>
                                                    {ingredient.measurement} {ingredient.name}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <hr/>
                                <h3>instructions:</h3>
                                <p>{recipe.mixin.preparation} Stir in or toss any amount with the {recipe.base.name} base before serving. How much? Trust yourself. We believe in you!</p>
                            </Card>
                        </LayoutBlock>
                    ) : null
                }
            </LayoutBlock>
        </React.Fragment>
    )
}

export default BaseTab;
