import React from 'react'
import { ImgContainer, ImgOverlay, LogoContainer } from './styles';

const Logo = ({size, mobileSize, overlayOpacity}) => (
    <LogoContainer $size={size} $mobileSize={mobileSize}>
        <ImgOverlay $overlayOpacity={overlayOpacity} />
        <ImgContainer alt="taconinja taco recipes" src={require('../../assets/logo.png')} />
    </LogoContainer>
);

export default Logo;
