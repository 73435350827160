import { SPICED_YOGURT_SAUCE, MINT_CHUTNEY } from '../standard/additionalToppings';
import {  } from '../standard/garnish';
import { POMEGRANITE_SEEDS } from '../standard/mixins';
import { FETA, MARINATED_FETA } from '../standard/queso';
import { CHARRED_TOMATO_RELISH } from '../standard/toppings';
import {  } from '../standard/tortilla';

const GREEK = {
    toppings: [CHARRED_TOMATO_RELISH],
    additionalToppings: [SPICED_YOGURT_SAUCE, MINT_CHUTNEY],
    garnishes: [],
    mixins: [POMEGRANITE_SEEDS],
    queso: [FETA, MARINATED_FETA],
    tortilla: []
};

export default GREEK;
