import React from 'react';
import { AwesomeButton } from 'react-awesome-button';
import './styles.css';
import { Container } from './styles';

function Button({
	isLarge,
	isSecondary,
	isProcessing,
	isNegativeAction,
	setWidth,
	isStageWidth,
	isCardWidth,
	children,
	type,
	onClick,
}) {
	const clickHandler = isProcessing ? null : onClick;
	return (
		<Container>
			<AwesomeButton
				disabled={isProcessing}
				ripple
				size={isLarge ? 'large' : 'small'}
				type={type}
				onPress={isProcessing ? () => {} : clickHandler}
			>
				{children}
			</AwesomeButton>
		</Container>
	);
}

export default Button;
