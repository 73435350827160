import { borderSmoothing, media, animation, colors } from '../../constants/commonStyles';
import { styled } from 'styletron-react';

const wipeAnimationSettings = {
    animationName: animation.keyframes.wipe,
    ...animation.settings.wipe,
    transform: 'translateX(-100%)',
    background: 'linear-gradient(90deg, transparent, rgba(255, 254, 255, .5), transparent)',
    width: '100%',
    height: '100%',
    zIndex: '100',
};

const enableTopMargin = {
    // TODO does this do anything?
};

export const Container = styled('div', ({ $injectStyles, $isShortDesktop, $addTopMargin, $isSelected }) => {

    const isSelectedStyles = {
        borderWidth: '2px',
        padding: '4px',
        borderStyle: 'solid',
        borderColor: colors().secondary,
        transition: 'all 0.10s ease',
        zIndex: '100',
        overflowX: 'hidden',
        backgroundColor: 'white',
        boxShadow: '0px 5px 15px 0 rgba(99,115,129,0.35)',
    };

	return ({
        ...borderSmoothing,
        float: 'left',
        position: 'relative',
        overflow: 'hidden',
        textAlign: 'left',
        cursor: 'pointer',
        transition: 'all 0.10s ease',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        zIndex: '10',
        ':hover': {
            borderColor: $isSelected ? colors().secondary : 'silver',
            borderWidth: '3px',
            padding: '3px',
        },
        height: '90px',
        minWidth: '90px',
        maxWidth: '90px',
        [`@media (max-width: ${media.mobileMaxWidth})`]: {
            minWidth: '75px',
            height: '100px',
        },
        ...$addTopMargin ? enableTopMargin : {},
        ...$isSelected ? isSelectedStyles : {},
		...$injectStyles || {},
	});
});

export const OuterContainer = styled('div', () => ({
    minWidth: '25%',
    maxWidth: '25%',
    height: '108px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-7px',
}));

export const ImageOverlay = styled('div', ({$isSelected}) => ({
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    overflow: 'hidden',
    top: '0',
    left: '0',
    zIndex: '0',
    width: 'inherit',
    ...($isSelected ? wipeAnimationSettings : {}),
}));

export default {
    Container,
    ImageOverlay,
    OuterContainer
};
