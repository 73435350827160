import React from 'react';
import { combineIngredients } from '../../utilities';
import { Card, Difficulty, LayoutBlock, RecipeTitle, IngredientList } from '..';

const HomeTab = ({ recipe, hyperbole, introPhrase, wantsCheese }) => {
    const extraInfo = (
        <React.Fragment>
            <hr />
            <p>Take a deep breath and center yourself. Here's how this is going to work. The main part of this experience will be the {recipe.base.name}, so make sure you like the sound of it before proceeding.  If this recipe isn't up your alley, go back and mash at the controls until you're feeling a bit more inspired.</p>
        </React.Fragment>
    );

    const getDifficultyNumber = () => {
        if (recipe.topping) {
            return Math.round((recipe.base.difficulty + recipe.topping.difficulty)/2)
        } else {
            return recipe.base.difficulty
        }
    };

    return (
        <>
            <LayoutBlock marginBottom={2}>
                <LayoutBlock marginTop={4}>
                    <h3>{introPhrase}:</h3>
                    <Card extraInfo={extraInfo}>
                        <RecipeTitle
                            recipe={recipe}
                            hyperbole={hyperbole}
                            conQueso={wantsCheese}
                        />
                        <Difficulty  forceRight floats difficulty={getDifficultyNumber()}>difficulty</Difficulty>
                    </Card>
                </LayoutBlock>
                <LayoutBlock horizontalSpacer={2} />
                <LayoutBlock>
                    <h4>here's everything you'll need:</h4>
                    <IngredientList list={combineIngredients(recipe, wantsCheese)} />
                </LayoutBlock>
            </LayoutBlock>
            <LayoutBlock />
        </>
    )
};

export default HomeTab;
