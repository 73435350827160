import {media, unit} from '../../constants/commonStyles';
import {styled} from 'styletron-react';

export const Container = styled('div',
    ({
         $flexCenter,
         $flexGrow,
         $halfWidthIfDesktop,
         $rowReverseIfDesktop,
         $row,
         $horizontalSpacer,
         $rowIfDesktop,
         $flexEnd,
         $injectStyles,
         $marginBottom,
         $marginTop,
         $flexCenterResponsive
     }) => {
        const flexCenter = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        };

        const flexEnd = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        };

        const horizontalSpacer = {
            minWidth: unit[$horizontalSpacer],
        };

        const flexCenterResponsive = {
            [`@media (max-width: ${media.mobileMaxWidth})`]: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            },
        };

        const rowIfDesktop = {
            [`@media (min-width: ${media.desktopMinWidth})`]: {
                display: 'flex',
                flexDirection: 'row',
                verticalAlign: 'middle',
                width: '100%',
            },
        };

        const row = {
            display: 'flex',
            flexDirection: 'row',
            verticalAlign: 'middle',
            width: '100%',
        };

        const halfWidthIfDesktop = {
            [`@media (min-width: ${media.desktopMinWidth})`]: {
                width: '50%',
            },
        };

        const rowReverseIfDesktop = {
            [`@media (min-width: ${media.desktopMinWidth})`]: {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                verticalAlign: 'middle',
            },
        };

        const flexGrow = {
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
        };

        return {
            marginBottom: unit[$marginBottom],
            marginTop: unit[$marginTop],
            ...$flexCenter ? flexCenter : {},
            ...$flexCenterResponsive ? flexCenterResponsive : {},
            ...$flexEnd ? flexEnd : {},
            ...$rowIfDesktop ? rowIfDesktop : {},
            ...$halfWidthIfDesktop ? halfWidthIfDesktop : {},
            ...$rowReverseIfDesktop ? rowReverseIfDesktop : {},
            ...$row ? row : {},
            ...$horizontalSpacer ? horizontalSpacer : {},
            ...$flexGrow ? flexGrow : {},
            ...$injectStyles || {},
        };
    });

export default {
    Container,
};
