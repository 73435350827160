import React from 'react';
import { Container } from './styles';

function AnimationWrapper({ children, cancelWidth, fromLeft, fromTop, toLeft, fromRight, toRight }) {
    return (
        <Container
            $cancelWidth={cancelWidth}
            $fromLeft={fromLeft}
            $fromTop={fromTop}
            $toLeft={toLeft}
            $toRight={toRight}
            $fromRight={fromRight}
        >
            { children }
        </Container>
    );
}

export default AnimationWrapper;
