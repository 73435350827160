import React from 'react';
import Ink from 'react-ink';
import { Container, ImageOverlay, OuterContainer } from './styles';

const OptionCard = ({
	alignCenter,
	children,
	injectStyles,
	isSelected,
    label,
    image,
	isShortDesktop,
	onClick,
    value,
    addTopMargin,
	...props
}) => (
	<React.Fragment>
		<OuterContainer $isShortDesktop={isShortDesktop}>
			<Container
				$isShortDesktop={isShortDesktop}
				$isSelected={isSelected}
				$addTopMargin={addTopMargin}
				$injectStyles={injectStyles}
				onClick={() => onClick(value)}
				{...props}
			>
				{ image }
				<ImageOverlay $isSelected={isSelected}>
					<Ink />
				</ImageOverlay>
				<Ink />
			</Container>
		</OuterContainer>
	</React.Fragment>
);

export default OptionCard;

