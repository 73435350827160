import React from 'react';
import Loader from 'react-loader-spinner';
import {colors} from '../../constants/commonStyles';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoadingSpinner = ({ size }) => (
    <Loader
        type="MutatingDots"
        secondaryColor={colors().secondary}
        color={colors().tertiary}
        height={size}
        width={size}
    />
);

export default LoadingSpinner;
