import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NFP7M85'
};

TagManager.initialize(tagManagerArgs);

const engine = new Styletron();

ReactDOM.render(
    <StyletronProvider value={engine}>
        <App />
    </StyletronProvider>
, document.getElementById('root'));

serviceWorker.unregister();
