import { dietaryCodes, baseTypes } from '../../constants'


export const CARAMELIZED_SHALLOTS = {
    name: "caramelized shallots",
    code: "cs",
    dietaryCodes: [dietaryCodes.VEGETARIAN],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.BRUNCH,
        baseTypes.INFINITE,
    ],
    ingredients: [
        {
            name: "shallots",
            measurement: "2",
            imageCode: "shallot"
        },
        {
            name: "butter (or olive-oil)",
            measurement: "1 tbsp",
            imageCode: "butter"
        }
    ],
    preparation: "peel, chop, and sautée on low until clear with hints of caramel color"
};

export const CHARRED_RED_BELL_PEPPERS = {
    name: "charred red bell peppers",
    code: "crbp",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    ingredients: [
        {
            name: "red bell pepper",
            measurement: "1/2 chopped",
            imageCode: "red-pepper"
        }
    ],
    preparation: "Heat a touch of vegetable oil on a pan or skillet at medium-high. Add peppers and a touch of salt and pepper. Stir occasionally for 8-10 minutes, or until soft and slightly charred."
};

export const CHOPPED_MANGO = {
    name: "chopped mango",
    code: "chmg",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    ingredients: [
        {
            name: "mango",
            measurement: "1 chopped",
            imageCode: "mango"
        }
    ],
    preparation: "Chopping a mango requires entire minutes of YouTube study to get right. You can learn better from them than you can from us."
};

export const CHOPPED_PINEAPPLE = {
    name: "chopped pineapple",
    code: "chppa",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.PORK,
        baseTypes.PORK,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "pineapple",
            measurement: "1/3 cup chopped",
            imageCode: "pineapple"
        }
    ],
    preparation: "pineapple prep goes here"
};

export const DICED_RED_ONION = {
    name: "diced red onion",
    code: "dro",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "red onion",
            measurement: "1",
            imageCode: "red-onion"
        }
    ],
    preparation: "Dice and toss or stir with your base recipe"
};

export const PICKLED_JALAPENOS = {
    name: "pickled jalapeños",
    code: "pkjl",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    ingredients: [
        {
            name: "pickled jalapeños",
            measurement: "1 tbsp chopped",
            imageCode: "jalapeños"
        }
    ],
    preparation: "Pickle your own for bonus points. The store bought jar is much easier, and you know what? They're good to have around."
};

export const PICKLED_RED_ONION = {
    name: "pickled red onion",
    code: "pro",
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    ingredients: [
        {
            name: "red onion",
            measurement: "1 julienned",
            imageCode: "red-onion"
        },
        {
            name: "red wine vinegar",
            measurement: "3 cups",
            imageCode: "vinegar"
        },
        {
            name: "sugar",
            measurement: "2 cups",
            imageCode: "sugar"
        }
    ],
    preparation: "In small pot, combine all the ingredients and bring to a boil, then remove from the heat and cool. Once cool, strain and reserve for your tacos."
};

export const POMEGRANITE_SEEDS = {
    name: "pomegranite seeds",
    code: "pmsds",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.INFINITE,
        baseTypes.LAMB,
        baseTypes.LAMB,
        baseTypes.LAMB,
    ],
    ingredients: [
        {
            name: "pomegranite seeds",
            measurement: "1/3 cup",
            imageCode: "pomegranite"
        }
    ],
    preparation: "pomegranite prep"
};

export const MIXINS = [
    CARAMELIZED_SHALLOTS,
    CHARRED_RED_BELL_PEPPERS,
    CHOPPED_MANGO,
    CHOPPED_PINEAPPLE,
    DICED_RED_ONION,
    PICKLED_JALAPENOS,
    PICKLED_RED_ONION,
    POMEGRANITE_SEEDS
];

export default MIXINS;
