export const baseTypes = {
    BEEF: "BEEF",
    CHICKEN: "CHICKEN",
    PORK: "PORK",
    BRUNCH: "BRUNCH",
    VEG: "VEG",
    LAMB: "LAMB",
    SEA: "SEA",
    INFINITE: "INFINITE",
};

export default baseTypes;
