import {  } from '../standard/additionalToppings';
import { CHOPPED_ICEBERG_LETTUCE } from '../standard/garnish';
import {  } from '../standard/mixins';
import { CHEDDAR, WHITE_CHEDDAR } from '../standard/queso';
import { CORN_RELISH } from '../standard/toppings';
import {  } from '../standard/tortilla';

const AMERICAN = {
    toppings: [CORN_RELISH],
    additionalToppings: [],
    garnishes: [
        CHOPPED_ICEBERG_LETTUCE
    ],
    mixins: [],
    queso: [
        CHEDDAR,
        WHITE_CHEDDAR
    ],
    tortilla: []
};

export default AMERICAN;
