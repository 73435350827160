import React from 'react';
import { MobileView } from 'react-device-detect';
import { Element } from 'react-scroll';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
    BaseTab,
    Body,
    Button,
    HomeTab,
    Gutter,
    Footer,
    LayoutBlock,
    Logo,
    ToppingsTab,
    RecipePDF,
    RecipePlaceholder,
    RecipeSceneHeader,
    SkeletonLoader,
    FooterExtraInfo,
} from '..'

import { Container } from './styles';

const RecipeScene = ({ onHide, recipe, wantsCheese, isProcessing, optionButtonsValue, introPhrase, hyperbole, welcomeMessage, ...props}) => {
    const RecipeComponent = () => (
    <>
        { isProcessing ? <SkeletonLoader /> :
            (
                <>
                    <RecipeSceneHeader
                        onHide={onHide}
                        recipe={recipe}
                        wantsCheese={wantsCheese}
                        hyperbole={hyperbole}
                    />
                    <LayoutBlock marginTop={4} row injectStyles={{maxHeight: '100%', overflowY: 'auto'}}>
                        <Gutter />
                        <Body modalBody>
                                <LayoutBlock marginTop={2}>
                                    <Element name='home-scroll'>
                                        <HomeTab
                                            introPhrase={introPhrase}
                                            recipe={recipe}
                                            wantsCheese={wantsCheese}
                                            hyperbole={hyperbole}
                                        />
                                    </Element>
                                    <LayoutBlock flexCenter marginTop={2} marginBottom={2}/>
                                    <Element name='base-scroll'>
                                        <BaseTab id="baseElement" recipe={recipe}/>
                                    </Element>
                                    <LayoutBlock flexCenter marginTop={2} marginBottom={2}/>
                                    <Element name='toppings-scroll'>
                                        <ToppingsTab
                                            recipe={recipe}
                                            wantsCheese={wantsCheese}
                                            optionButtonsValue={optionButtonsValue}
                                        />
                                    </Element>
                                    <LayoutBlock flexCenter marginTop={2}>
                                        <Logo overlayOpacity={.81} size={90} />
                                    </LayoutBlock>
                                </LayoutBlock>
                        </Body>
                        <Gutter />
                    </LayoutBlock>
                    <Footer extraInfo={FooterExtraInfo()} >
                        <LayoutBlock marginTop={0} flexCenter>
                            <LayoutBlock marginBottom={2}>
                                <MobileView>
                                    <Button
                                        isLarge
                                        type='secondary'
                                        onClick={onHide}
                                        injectStyles={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            position: 'fixed',
                                            zIndex: '10000',
                                            padding: '8px 18px 8px 8px',
                                            backgroundColor: 'white',
                                            width: '100%',
                                            top: 0,
                                            boxShadow: '0 2px 5px 0 rgba(99,115,129,0.35)',
                                        }}>
                                        back to the generator
                                    </Button>
                                </MobileView>
                                <LayoutBlock horizontalSpacer={6}/>
                                <PDFDownloadLink
                                    document={<RecipePDF
                                        recipe={recipe}
                                        hyperbole={hyperbole}
                                        conQueso={wantsCheese}
                                    />}
                                    fileName={`your_${recipe.base.name.replace(/ /g, '_')}_taco.pdf`}>
                                    <Button isLarge>download recipe pdf</Button>
                                </PDFDownloadLink>
                            </LayoutBlock>
                        </LayoutBlock>
                    </Footer>
                </>)}
        </>
    );

    return (
        <Container $isProcessing={isProcessing}>
            { recipe ? <RecipeComponent { ...props } /> : <RecipePlaceholder welcomeMessage={welcomeMessage}/> }
        </Container>
    )
};

export default RecipeScene;
