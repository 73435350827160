import { WHIPPED_COCONUT_CREAM, SPICED_YOGURT_SAUCE, MINT_CHUTNEY } from '../standard/additionalToppings';
import {  } from '../standard/garnish';
import { CHOPPED_MANGO } from '../standard/mixins';
import {  } from '../standard/queso';
import { MANGO_GUAC } from '../standard/toppings';
import {  } from '../standard/tortilla';

const INDIAN = {
    toppings: [MANGO_GUAC],
    additionalToppings: [
        WHIPPED_COCONUT_CREAM,
        SPICED_YOGURT_SAUCE,
        MINT_CHUTNEY,
        MINT_CHUTNEY
    ],
    garnishes: [],
    mixins: [CHOPPED_MANGO],
    queso: [],
    tortilla: []
};

export default INDIAN;
