import randomize from './randomizer';

const authors = [
    "Lao Tzu",
    "Tomoe Gozen (巴 御前)",
    "Sun Tzu",
    "Ancient Chinese Proverb",
    "Toyotomi Hideyoshi (豊臣 秀吉)",
    "Kusunoki Masashige (楠木 正成)",
];

const quotes = [
    "By the very nature of your presence here, it is known that you have fully awakened to your taco potential.",
    "Live everyday like it's Taco Tuesday.",
    "I wonder if there's a taco out there thinking of me too.",
    "A balanced diet is a taco in each hand.",
    "There is no 'we' in tacos.",
    "I wish I was full of tacos instead of emotions.",
    "Ways to My Heart: Buy Me Tacos, Make Me Tacos, Be Tacos.",
    "You cannot make everybody happy, you are not a taco.",
    "Yesterday I really wanted tacos. Today, I’m eating tacos. Follow your dreams.",
    "I'm into fitness. Fit'ness taco in my mouth.",
    "All I'm saying is- you've never seen me crying and eating tacos at the same time.",
    "Tacocat spelled backwards is ... tacocat.",
    "Prepare to embark upon a magical, mystical journey of taco creation."
];

export function getWelcomeMessage() {
    return {
        author: randomize(authors),
        quote: randomize(quotes)
    }
}
