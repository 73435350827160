// Styles for SkeletonListItem
import { styled } from 'styletron-react';
import { loadingSettings, loadingKeyframes, media } from '../../constants/commonStyles';

export const Container = styled('div', () => ({
    width: '100%',
    minHeight: `${window.innerHeight}px`,
    // marginLeft: '-10px',
    // overflow: 'hidden',
}));

export const RowContainer = styled('div', ({ $injectStyles }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '-30px',
    maxWidth: media.gutterTrigger,
    ...($injectStyles || {}),
}));

const headlinePlaceholderStyles = {
    marginBottom: '4px',
};

const animationSettings = {
    animationName: loadingKeyframes,
    ...loadingSettings,
    transform: 'translateX(-100%)',
    background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, .5), transparent)',
    width: '60%',
    height: '100%',
    zIndex: '100',
};

const grayBackground = {
        backgroundColor: '#F1F4F6',
};

export const HeadlinePlaceholder = styled('div', ({ height, width, $borderradius, $marginTop, $marginLeft }) => ({
    ...grayBackground,
    ...headlinePlaceholderStyles,
    height: height || '21px',
    width: width || '50%',
    borderRadius: $borderradius || '3px',
    zIndex: '10',
    marginTop: $marginTop || '10px',
    marginLeft: $marginLeft || '0px'
}));

export const HeaderPlaceholder = styled('div', () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '-24px',
    height: '77px',
    backgroundColor: 'white',
    boxShadow: '0 2px 5px 0 rgba(99,115,129,0.35)',
}));

export const HeadlinePlaceholderAnimationOverlay = styled('div', ({width}) => ({
    ...headlinePlaceholderStyles,
    height: '100%',
    width: width || '50%',
    ...animationSettings,
}));

export default {
    Container,
    RowContainer,
    HeadlinePlaceholder,
    HeadlinePlaceholderAnimationOverlay,
    HeaderPlaceholder
};
