import { styled } from 'styletron-react';
import { unit, borderSmoothing, media, shadow, colors } from '../../constants/commonStyles';

const clickableStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    ':hover': {
        boxShadow: '0 5px 15px 0 rgba(99,115,129,0.35)',
        cursor: 'pointer',
        transition: 'all 0.15s ease',
    },
};

const enableFlex = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
};

const enableFlexColumn = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
};

const enableFlexCenter = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
};

export const CaretContainer = styled('div', ({ onClick }) => ({
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '-6px',
    ...onClick ? enableFlexColumn : {},
}));

export const InfoContainer = styled('div', ({ $extraInfo }) => ({
    display: 'none',
    alignSelf: 'center',
    marginRight: '-2px',
    marginLeft: unit[2],
    height: '100%',
    ...$extraInfo ? enableFlex : {},
}));

export const TopContainer = styled('div', ({ $extraInfo, onClick, $injectStyles, $flexCenter }) => ({
    width: '100%',
    ...($extraInfo ? enableFlex : {}),
    ...(onClick ? enableFlexColumn : {}),
    ...($flexCenter ? enableFlexCenter : {}),
    ...($injectStyles || {}),
}));

export const ExtraInfoContainer = styled('div', () => ({
}));

const maintainFullWidth = {
    [`@media (min-width: ${media.desktopMinWidth})`]: {
        minWidth: '700px',
    },
};

export const Container = styled('div', ({ $clickable, $isShortDesktop, $maintainFullWidth, onClick, $injectStyles, $contentWidth, $setWidthDesktop }) => ({
    maxWidth: '1200px',
    height: 'min-content',
    ...borderSmoothing,
    padding: unit[2],
    ...shadow,
    overflow: 'visible',
    backgroundColor: colors().white,
    marginRight: '5px',
    marginLeft: '3px',
    [`@media (min-width: ${media.desktopMinWidth})`]: {
        width: `${$setWidthDesktop}%`,
    },
    ...onClick ? clickableStyles : {},
    ...$maintainFullWidth ? maintainFullWidth : {},
    ...$isShortDesktop ? { padding: '8px 16px 8px 16px' } : {},

}));

export default { Container, CaretContainer, InfoContainer, ExtraInfoContainer };
