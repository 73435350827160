import { dietaryCodes, baseTypes } from '../../constants'


export const CHOPPED_ROMAINE_LETTUCE = {
    name: "chopped romaine lettuce",
    code: "chrm",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "romaine",
            measurement: "1 cup chopped",
            imageCode: "green-lettuce"
        }
    ]
};

export const CHOPPED_ICEBERG_LETTUCE = {
    name: "chopped iceberg lettuce",
    code: "chib",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "iceberg",
            measurement: "1 cup chopped",
            imageCode: "green-lettuce"
        }
    ]
};

export const CHOPPED_BOSTON_LETTUCE = {
    name: "chopped boston lettuce",
    code: "chpblt",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "boston lettuce",
            measurement: "1 cup chopped",
            imageCode: "green-lettuce"
        }
    ]
};

export const FRISEE = {
    name: "frisée",
    code: "frse",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "frisée",
            measurement: "1 1/2 cups",
            imageCode: "green-lettuce"
        }
    ]
};

export const SHAVED_BRUSSELS_SPROUTS = {
    name: "shaved brussels sprouts",
    code: "shdbrs",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.SEA,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "brussels sprouts",
            measurement: "1/2 cup chopped",
            imageCode: "brussels"
        }
    ]
};

export const CHOPPED_GREEN_LEAF_LETTUCE = {
    name: "chopped green-leaf lettuce",
    code: "grnll",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "red-leaf lettuce",
            measurement: "1 cup chopped",
            imageCode: "green-lettuce"
        }
    ]
};

export const CHOPPED_RED_LEAF_LETTUCE = {
    name: "chopped red-leaf lettuce",
    code: "chrll",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "red-leaf lettuce",
            measurement: "1 cup chopped",
            imageCode: "red-lettuce"
        }
    ]
};

export const CILANTRO = {
    name: "fresh cilantro",
    code: "frcil",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE,
    ],
    ingredients: [
        {
            name: "cilantro",
            measurement: "1 pinch fresh",
            imageCode: "cilantro"
        }
    ]
};

export const PARSLEY = {
    name: "fresh parsley",
    code: "frprs",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "parsley",
            measurement: "1 pinch fresh",
            imageCode: "cilantro"
        }
    ]
};

export const ARUGULA = {
    name: "arugula",
    code: "argl",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "arugula",
            measurement: "1 pinch fresh",
            imageCode: "arugula"
        }
    ]
};

export const SLICED_RADISH = {
    name: "sliced radish",
    code: "slrd",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.CHICKEN,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.PORK,
        baseTypes.PORK,
        baseTypes.LAMB,
        baseTypes.BRUNCH,
        baseTypes.INFINITE,
        baseTypes.INFINITE
    ],
    ingredients: [
        {
            name: "radishes",
            measurement: "3 thinly-chopped",
            imageCode: "radish"
        }
    ]
};

export const RED_CABBAGE = {
    name: "chopped red cabbage",
    code: "chrdcb",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.BEEF,
        baseTypes.CHICKEN,
        baseTypes.LAMB,
        baseTypes.PORK,
        baseTypes.VEG,
        baseTypes.SEA,
        baseTypes.SEA,
        baseTypes.SEA,
        baseTypes.SEA
    ],
    ingredients: [
        {
            name: "red cabbage",
            measurement: "1/2 cup chopped",
            imageCode: "red-cabbage"
        }
    ]
};

export const GREEN_CABBAGE = {
    name: "chopped green cabbage",
    code: "chgcb",
    dietaryCodes: [
        dietaryCodes.VEGAN,
        dietaryCodes.VEGETARIAN
    ],
    basePairings: [
        baseTypes.SEA,
        baseTypes.SEA,
    ],
    ingredients: [
        {
            name: "green cabbage",
            measurement: "1/2 cup chopped",
            imageCode: "green-cabbage"
        }
    ]
};

export const GARNISHES = [
    CHOPPED_ROMAINE_LETTUCE,
    CHOPPED_ICEBERG_LETTUCE,
    CHOPPED_BOSTON_LETTUCE,
    FRISEE,
    SHAVED_BRUSSELS_SPROUTS,
    CHOPPED_GREEN_LEAF_LETTUCE,
    CHOPPED_RED_LEAF_LETTUCE,
    CILANTRO,
    CILANTRO,
    PARSLEY,
    ARUGULA,
    SLICED_RADISH,
    RED_CABBAGE,
    GREEN_CABBAGE
];

export default GARNISHES;
